<template>
  <!-- Emergency Contact -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Emergency Contact</h2>
        <div class="flex items-start cursor-pointer">
          <span
            v-if="getEmergencyContacts.length < 2"
            class="material-icons"
            @click="emergencyContactModal()"
          >
            edit
          </span>
        </div>
      </div>
      <span v-for="(contact, index) in filteredArray" :key="contact.id">
        <div class="flex justify-between mt-3 ml-3">
          <h4
            class="font-bold cursor-pointer transition ease-in-out delay-100 hover:text-orange"
            @click="emergencyContactModal(contact)"
            v-if="contact.is_primary === 1"
          >
            Primary
          </h4>
          <h4
            class="font-bold cursor-pointer transition ease-in-out delay-100 hover:text-orange"
            @click="emergencyContactModal(contact)"
            v-else
          >
            Contact
          </h4>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Name:</p>
          <p class="w-2/5">
            {{ contact.name.toUpperCase() }}
          </p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Relationship:</p>
          <p class="w-2/5">
            {{ contact.relationship.toUpperCase() }}
          </p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Primary number:</p>
          <p class="w-2/5">
            {{ contact.primary_phone.toUpperCase() }}
          </p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Secondary number:</p>
          <p class="w-2/5">
            {{ contact.secondary_phone.toUpperCase() }}
          </p>
        </div>
        <hr v-if="index === 0" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["modalTrigger"],
  props: {
    getEmergencyContacts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEmergencyContactModal: false,
      filteredArray: null,
    };
  },
  created() {
    // Takes original Data Array Sorts it so the PRIMARY is the first element and saves a copy in the variable
    this.filteredArray = [...this.getEmergencyContacts].sort((a, b) => {
      return b.is_primary - a.is_primary;
    });
  },
  methods: {
    emergencyContactModal(data) {
      this.showEmergencyContactModal = !this.showEmergencyContactModal;
      this.$emit("modalTrigger", data);
    },
  },
};
</script>
