<template>
  <!--  Induction Checklist -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Induction Checklist</h2>
        <div class="flex items-start cursor-pointer">
          <span class="material-icons">
            edit
          </span>
        </div>
      </div>
      <div class="m-2 ml-3">
        <label for="inductionDate" class="">Induction Date:</label>
        <br />
        <input class="w-1/2 h-12 rounded-md border-2 border-grey" type="date" />
      </div>
      <table class="w-full table-fixed text-center">
        <thead>
          <tr>
            <th class="w-2/5 py-2 text-left pl-2">Induction</th>
            <th class="w-1/4">Completed</th>
            <th class="w-1/3">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Welcome & introduction to the team
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Organisational structure
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Opening times
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Contract - signed
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Working hours
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Facilities - Lockers, Kitchen, Toilets, etc.
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Food & drink - incl. lunchtimes
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Dress code / uniform
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Salary incl. pay rates
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Absence reporting (sickness, lateness, etc.) incl. to who & how
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Annual leave - Requesting & allowance
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Training & development - incl. mentor & reviews
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Health & safety at work - incl. accident & reviews
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Emergency procedures - incl. fire alarm, evacuation, panic button,
              etc.
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Restricted access, door codes, keys, etc.
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Confidentiality of data
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="underline cursor-pointer text-left pl-2">
              Access applied for; (i.e. smartcard, network access, email, IT
              packages, etc) - Give details
            </td>
            <td><input type="checkbox" /></td>
            <td>
              <textarea
                class="border-2 border-grey rounded-md w-11.5/12"
                type="text"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-center items-center">
        <base-button>Submit</base-button>
      </div>
    </div>
  </div>
</template>
