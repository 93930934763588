<template>
  <!-- Contract Summary -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Contract Summary</h2>
        <div class="flex items-start cursor-pointer">
          <span class="material-icons" @click="contractSummaryModal()">
            edit
          </span>
        </div>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Employee type:</p>
        <p class="w-2/5">
          {{ getContractSummary.employee_type.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lor" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Employee start date:</p>
        <p class="w-2/5">
          {{ getContractSummary.employee_start_date.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Contract start date:</p>
        <p class="w-2/5">
          {{ getContractSummary.contract_start_date.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Working time pattern:</p>
        <p class="w-2/5">
          {{ getContractSummary.working_time_pattern.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Contracted hours per week:</p>
        <p class="w-2/5">
          {{ getContractSummary.contracted_hours_per_week.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Min. leave entitlement:</p>
        <p class="w-2/5">
          {{ getContractSummary.min_leave_entitlement.toUpperCase() }}
        </p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Contract:</p>
        <!-- <p class="underline cursor-pointer w-2/5">Lorem Ipsum</p> -->
        <a
          class="w-2/5 underline cursor-pointer"
          :href="getContractSummary.contract_document"
          target="_blank"
          ><span class="material-icons text-sm"> description </span>
          Contract
        </a>
        <!-- <p class="w-2/5">
          {{ getContractSummary.contract_document }}
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["modalTrigger"],
  data() {
    return {
      showContractSummaryModal: false,
    };
  },
  methods: {
    contractSummaryModal() {
      this.showContractSummaryModal = !this.showContractSummaryModal;
      this.$emit("modalTrigger", this.showContractSummaryModal);
    },
  },
  computed: {
    getContractSummary() {
      return this.$store.getters["profile/getContractSummary"];
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
