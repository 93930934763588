<template>
  <div class="border border-grey">
    <div class="flex justify-between px-3">
      <h2 class="text-orange pt-2">Sickness</h2>
      <p class="border text-white bg-teal rounded-3xl px-1 my-2 cursor-pointer">
        ADD
      </p>
    </div>
    <div class="flex justify-center pt-3">
      <p class="text-5xl">2</p>
    </div>
    <div class="flex justify-center">
      <p class="text-xl text-grey">OCCURENCES</p>
    </div>
    <div class="p-3">
      <p class="font-bold">
        Details <span class="font-normal">(Latest):</span>
      </p>
      <p class="underline text-sm">Mon 30 Jul - EST. Mon 05 Aug - (Ongoing)</p>
      <p class="text-sm pt-1">Details:</p>
      <p class="font-bold text-sm">
        Logged <span class="font-normal">by Manager - </span
        ><span class="underline">1 Attachment</span>
      </p>
      <div class="flex justify-between pt-2">
        <p class="font-bold">Self Certification:</p>
        <p>Complete</p>
      </div>
      <div class="flex justify-between pt-2">
        <p class="font-bold">Return to work:</p>
        <p>Pending</p>
      </div>
      <div class="flex justify-end underline cursor-pointer">
        <p>More...v</p>
      </div>
    </div>
  </div>
</template>
