<template>
  <form @submit.prevent="submitModal">
    <div
      class="flex justify-center h-screen w-screen items-center z-20 absolute top-0 left-0 -translate-x-1/2"
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="hideScroll flex flex-col min-w-lg max-w-6xl mx-auto rounded-lg border border-lighGrey z-20 overflow-scroll max-h-5/6 overflow-x-hidden"
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Contract Summary
          </h2>
          <!-- Employee Type / Employee Start Date -->
          <div class="flex flex-col items-center md:flex-row my-1 md:mx-10">
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="employee_type"
                >Employee Type</label
              >
              <select
                name="employee_type"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
                v-model="employee_type"
              >
                <option value="">Choose an option</option>
                <option value="full-time">Full-Time</option>
                <option value="part-time">Part-Time</option>
                <option value="contractor">Contractor</option>
              </select>
            </div>
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="employee_start_date"
                >Employee Start Date</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                type="text"
                name="employee_start_date"
                v-model="employee_start_date"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
              />
            </div>
          </div>
          <!-- Contract Start Date / Working Hours Pattern -->
          <div class="flex flex-col items-center md:flex-row my-1 md:mx-10">
            <!-- Contract Start Date -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="contract_start_date"
                >Contract Start Date</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                type="text"
                name="contract_start_date"
                v-model="contract_start_date"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
              />
            </div>
            <!-- Working Hours Pattern -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="working_time_pattern"
                >Working Hours Pattern</label
              >
              <select
                name="working_time_pattern"
                id="position-select"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
                v-model="working_time_pattern"
                @change="updatePattern($event)"
              >
                <option
                  v-for="pattern in getWorkPatterns"
                  :key="pattern.id"
                  :value="pattern.id"
                >
                  {{ pattern.name }}
                </option>
              </select>
            </div>
          </div>
          <!-- Divider -->
          <!-- <div>
            <h3
              class="w-4/5 mt-5 mx-auto overflow-hidden text-center text-orange"
            >
              New working pattern
            </h3>
          </div> -->
          <!-- NEW WORKING PATTERN (PATTERN NAME) -->
          <!-- <div class="flex flex-col items-center md:flex-row my-1 md:mx-10">
            <div class="w-full">
              <label
                class="font-semibold text-grey flex pb-1"
                for="newPatternName"
                >Pattern Name</label
              >
              <div class="relative w-full">
                <input
                  type="text"
                  name="newPatternName"
                  v-model="newPatternName"
                  placeholder="New Pattern Name"
                  class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11.5/12 h-10 pl-2"
                />
              </div>
            </div>
          </div> -->
          <!-- NEW WORKING PATTERN (CONTROLS) -->
          <div
            class="flex flex-col items-center lg:flex-row my-1 md:mx-10"
            v-for="(pattern, index) in getWorkTimings"
            :key="index"
          >
            <!-- Start / End / Break Time -->
            <div class="w-full lg:w-1/2">
              <div class="flex">
                <div class="lg:flex-1">
                  <label class="font-semibold text-grey pb-1" for="start_time"
                    >Start Time</label
                  >
                  <input
                    disabled
                    placeholder="Start"
                    type="time"
                    name="start_time"
                    v-model="pattern.start_time"
                    class="rounded-lg border border-darkGrey bg-lightGrey w-full h-10 pl-2"
                  />
                </div>
                <div class="lg:flex-1 ml-3">
                  <label class="font-semibold text-grey pb-1" for="end_time"
                    >End Time</label
                  >
                  <input
                    disabled
                    placeholder="End"
                    type="time"
                    name="end_time"
                    v-model="pattern.end_time"
                    class="rounded-lg border border-darkGrey bg-lightGrey w-full h-10 pl-2"
                  />
                </div>
                <div class="lg:flex-1 ml-3">
                  <label class="font-semibold text-grey pb-1" for="break_time"
                    >Break</label
                  >
                  <input
                    disabled
                    placeholder="Break"
                    type="number"
                    name="break_time"
                    v-model="pattern.break_time"
                    class="rounded-lg border border-darkGrey bg-lightGrey w-full h-10 pl-2"
                  />
                </div>
              </div>
            </div>
            <!-- Days Checklist -->
            <div class="w-full lg:w-1/2 pb-2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="working_time_pattern"
                >Repeat</label
              >
              <div class="w-full flex justify-between">
                <!-- Monday -->
                <article
                  class="relative w-9 h-9 m-1 border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Monday"
                    value="monday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    M
                  </div>
                </article>
                <!-- Tuesday -->
                <article
                  class="relative w-9 h-9 m-1 border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Tuesday"
                    value="tuesday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    T
                  </div>
                </article>
                <!-- Wednesday -->
                <article
                  class="relative w-9 h-9 m-1 float-left border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Wednesday"
                    value="wednesday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    W
                  </div>
                </article>
                <!-- Thursday -->
                <article
                  class="relative w-9 h-9 m-1 float-left border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Thursday"
                    value="thursday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    T
                  </div>
                </article>
                <!-- Friday -->
                <article
                  class="relative w-9 h-9 m-1 float-left border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Friday"
                    value="friday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    F
                  </div>
                </article>
                <!-- Saturday -->
                <article
                  class="relative w-9 h-9 m-1 float-left border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Saturday"
                    value="saturday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    S
                  </div>
                </article>
                <!-- Sunday -->
                <article
                  class="relative w-9 h-9 m-1 float-left border-2 border-lightGrey box-border"
                >
                  <input
                    disabled
                    type="checkbox"
                    name="Synday"
                    value="sunday"
                    v-model="pattern.repeat_days"
                    class="absolute top-0 left-0 w-9 h-9 opacity-0"
                  />
                  <div
                    class="w-full h-full flex justify-center items-center transition duration-500"
                  >
                    S
                  </div>
                </article>
                <!-- <div class="flex justify-end items-center">
                  <span
                    class="material-icons cursor-pointer text-grey"
                    @click.prevent="deleteOption(index)"
                  >
                    delete
                  </span>
                </div> -->
              </div>
            </div>
          </div>
          <!-- Add New Item -->
          <!-- <div
            v-if="
              working_time_pattern === 'newPattern' && workPattern.length < 7
            "
            class="flex justify-end bg-white pb-2 mr-10"
          >
            <span
              class="material-icons cursor-pointer text-teal"
              @click.prevent="addOption"
            >
              add_circle_outline
            </span>
          </div> -->
          <!-- Contracted hours per week / Min. leave entitlement -->
          <div class="flex flex-col items-center md:flex-row my-1 md:mx-10">
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="contracted_hours_per_week"
                >Contracted hours per week</label
              >
              <input
                placeholder=""
                type="number"
                name="contracted_hours_per_week"
                v-model="contracted_hours_per_week"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
              />
            </div>
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="min_leave_entitlement"
                >Min. leave entitlement</label
              >
              <input
                placeholder=""
                type="number"
                name="min_leave_entitlement"
                v-model="min_leave_entitlement"
                class="rounded-lg border border-darkGrey bg-lightGrey w-full md:w-11/12 h-10 pl-2"
              />
            </div>
          </div>
          <!-- Attachment / Current Contract -->
          <div class="flex flex-col items-center md:flex-row my-1 md:mx-10">
            <!-- Attachment -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="attachment"
                >Contract</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="file"
                  name="attachment"
                  class="relative text-right opacity-0 z-20 w-full md:w-11/12 h-10 pl-2"
                  @change="uploadFile"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="rounded-lg border bg-lightGrey w-full md:w-11/12 h-10 pl-2"
                    :class="[fileSizeOK ? 'border-red' : 'border-darkGrey']"
                    :placeholder="
                      !attachment
                        ? 'Click here to attach a file (< 1MB)...'
                        : attachment.name
                    "
                  />
                </div>
              </div>
              <span v-if="fileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Current Contract -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="min_leave_entitlement"
                >Current Contract</label
              >
              <a
                class="w-full md:w-11/12 h-10 pl-2 underline cursor-pointer"
                :href="getContractSummary.contract_document"
                target="_blank"
                ><span class="material-icons text-sm"> description </span>
                Contract
              </a>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div
          class="flex flex-row items-center justify-end p-5 bg-white border-t border-lightGrey rounded-bl-lg rounded-br-lg"
        >
          <p
            class="px-11 py-2 text-white font-semibold bg-orange rounded-full cursor-pointer"
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  created() {
    this.employee_type = this.getContractSummary.employee_type;
    this.employee_start_date = this.getContractSummary.employee_start_date;
    this.contract_start_date = this.getContractSummary.contract_start_date;
    this.working_time_pattern = this.getContractSummary.working_time_pattern;
    this.contracted_hours_per_week =
      this.getContractSummary.contracted_hours_per_week;
    this.min_leave_entitlement = this.getContractSummary.min_leave_entitlement;
    this.workPattern = this.getWorkPatterns;
    this.fetchWorkTimings(this.getContractSummary.working_time_pattern);
  },
  data() {
    return {
      isWorkingPatternLoading: false,
      isWorkingTimmingsLoading: false,
      employeType: "",
      employee_start_date: "",
      contract_start_date: "",
      working_time_pattern: "",
      newPatternName: "",
      workPattern: null,

      // workPattern: [
      //   {
      //     startTime: "09:00",
      //     endTime: "18:00",
      //     breakTime: 30,
      //     days: ["saturday"],
      //   },
      //   {
      //     startTime: "10:00",
      //     endTime: "16:00",
      //     breakTime: 30,
      //     days: ["sunday"],
      //   },
      // ],
      contracted_hours_per_week: "",
      min_leave_entitlement: "",
      attachment: null,
      fileSizeOK: false,
    };
  },
  methods: {
    updatePattern(event) {
      this.fetchWorkTimings(event.target.value);
    },
    async fetchWorkTimings(payload) {
      try {
        const actionPayload = {
          work_pattern: payload,
        };
        // this.isWorkingTimmingsLoading = true;
        await this.$store.dispatch("profile/loadWorkTimings", actionPayload);
        // this.isWorkingTimmingsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // addOption() {
    //   this.workPattern.push({
    //     start_time: "",
    //     end_time: "",
    //     break_time: "",
    //     repeat_days: [],
    //   });
    // },
    // deleteOption(index) {
    //   this.workPattern.splice(index, 1);
    // },
    uploadFile() {
      this.attachment = this.$refs.file.files[0];
      if (this.attachment.size > 1024 * 1024) {
        this.attachment = null;
        return (this.fileSizeOK = true);
      } else {
        return;
      }
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = {
        contract_summary: this.getContractSummary.id,
        employee_type: this.employee_type,
        employee_start_date: this.employee_start_date,
        contract_start_date: this.contract_start_date,
        working_time_pattern: this.working_time_pattern,
        contracted_hours_per_week: this.contracted_hours_per_week.toString(),
        min_leave_entitlement: this.min_leave_entitlement.toString(),
        contract_document: null,
      };
      if (this.attachment) {
        payload.contract_document = this.attachment.file;
      }
      this.$emit("submit-modal", "profile/updateContractSummary", payload);
    },
  },
  computed: {
    getContractSummary() {
      return this.$store.getters["profile/getContractSummary"];
    },
    getWorkPatterns() {
      return this.$store.getters["profile/getWorkPatterns"];
    },
    getWorkTimings() {
      return this.$store.getters["profile/getWorkTimings"];
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}
input[type="checkbox"]:checked ~ div {
  background-color: #ed8b00;
  color: white;
}
</style>
