<template>
  <teleport to="body">
    <contract-summary-modal
      v-if="!workPattermIsLoading"
      :animateButton="contractSummaryAnimateButton"
      :class="{ hidden: showContractSummary }"
      @submit-modal="submitContractSummaryModal"
      @close-modal="toggleContractModal"
    ></contract-summary-modal>
    <position-information-modal
      :animateButton="positionInformationAnimateButton"
      :class="{ hidden: showPositionInformation }"
      @submit-modal="submitPositionInformation"
      @close-modal="toggleSummaryModal"
    ></position-information-modal>
    <miscellaneous-information-modal
      v-if="!miscIsLoading"
      :animateButton="miscellaneousInformationAnimateButton"
      :getMiscData="getMiscData"
      :class="{ hidden: showMiscellaneousInformation }"
      @submit-modal="submitMiscInformation"
      @close-modal="toggleMiscellaneousModal"
    ></miscellaneous-information-modal>
    <employment-checks-modal
      v-if="!employmentChecksIsLoading"
      :animateButton="employmentChecksAnimateButton"
      :getEmploymentChecks="getEmploymentChecks"
      :class="{ hidden: showEmploymentChecks }"
      @submit-modal="submitEmploymentChecks"
      @close-modal="toggleEmploymentChecksModal"
    ></employment-checks-modal>
    <!-- <employment-policies-modal
      v-if="!employmentPoliciesIsLoading"
      :animateButton="employmentPoliciesAnimateButton"
      :class="{ hidden: showEmploymentPolicies }"
      :getEmploymentPolicies="getEmploymentPolicies"
      @submit-modal="submitEmploymentPolicies"
      @close-modal="toggleEmploymentPoliciesModal"
    ></employment-policies-modal> -->
    <!-- <registrations-modal
      v-if="!registrationsIsLoading"
      :animateButton="registrationsAnimateButton"
      :getRegistrations="getRegistrations"
      :class="{ hidden: showRegistrations }"
      @submit-modal="submitRegistrations"
      @close-modal="toggleRegistrationsModal"
    ></registrations-modal> -->
    <emergency-contact-modal
      v-if="!showEmergencyContact"
      :animateButton="emergencyContactAnimateButton"
      :class="{ hidden: showEmergencyContact }"
      :getEmergencyContacts="singleEmergencyContact"
      @submit-modal="submitEmergency"
      @delete-item="deleteEmergencyContact"
      @submit-modal-create="submitCreateEmergencyContact"
      @close-modal="toggleEmergencyContactModal"
    ></emergency-contact-modal>
    <education-information-modal
      v-if="!showEducationInformation"
      :getEducationInformation="education"
      :animateButton="educationInformationAnimateButton"
      :class="{ hidden: showEducationInformation }"
      @submit-modal-create="submitEducationCreate"
      @submit-modal="submitEducation"
      @close-modal="toggleEducationInformationModal"
      @delete-item="deleteEducation"
    ></education-information-modal>
    <references-modal
      v-if="!showReferences"
      :getReferencesData="references"
      :animateButton="referencesAnimateButton"
      :class="{ hidden: showReferences }"
      @submit-modal-create="submitReferencesCreate"
      @submit-modal="submitReferences"
      @close-modal="toggleReferencesModal"
      @delete-item="deleteReference"
    ></references-modal>
    <!-- <experiences-modal
      v-if="!experiencesInformationIsLoading"
      :animateButton="experiencesAnimateButton"
      :getExperiencesInformation="getExperiencesInformation"
      :class="{ hidden: showExperiences }"
      @submit-modal="submitModal"
      @close-modal="toggleExperiencesModal"
    ></experiences-modal> -->
    <experiences-modal-single
      v-if="!showExperiences"
      :animateButton="experiencesAnimateButton"
      :getExperiencesInformation="experiences"
      :class="{ hidden: showExperiences }"
      @submit-modal-create="submitExperiencesCreate"
      @submit-modal="submitExperiences"
      @close-modal="toggleExperiencesModal"
      @delete-item="deleteExperience"
    ></experiences-modal-single>
  </teleport>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 m-auto w-11.5/12">
    <!-- GRID LEFT -->
    <div class="grid">
      <contract-summary @modalTrigger="toggleContractModal" />
      <miscellaneous-information
        v-if="!miscIsLoading"
        :getMiscData="getMiscData"
        @modalTrigger="toggleMiscellaneousModal"
      />
      <employment-policies
        v-if="!employmentPoliciesIsLoading"
        :getEmploymentPolicies="getEmploymentPolicies"
        @modalTrigger="toggleEmploymentPoliciesModal"
        @policySign="signEmploymentPolicy"
      />
      <div>
        <experience-information
          v-if="!experiencesInformationIsLoading"
          :getExperiencesInformation="getExperiencesInformation"
          @modalTrigger="toggleExperiencesModal"
        />
      </div>

      <reference
        v-if="!referencesIsLoading"
        :getReferencesData="getReferencesData"
        @modalTrigger="toggleReferencesModal"
      />
      <div>
        <education-information
          v-if="!educationInformationIsLoading"
          :getEducationInformation="getEducationInformation"
          @modalTrigger="toggleEducationInformationModal"
        />
      </div>
      <registrations
        v-if="!registrationsIsLoading"
        :getRegistrations="getRegistrations"
        @modalTrigger="toggleRegistrationsModal"
      />
    </div>
    <!-- GRID RIGHT -->
    <div class="grid">
      <position-information @modalTrigger="toggleSummaryModal" />
      <!-- <employment-checks
        v-if="!employmentChecksIsLoading"
        :getEmploymentChecks="getEmploymentChecks"
        @modalTrigger="toggleEmploymentChecksModal"
      /> -->
      <induction-checklist />
      <emergency-contact
        v-if="!emergencyContactIsLoading"
        :getEmergencyContacts="getEmergencyContacts"
        @modalTrigger="toggleEmergencyContactModal"
      />
    </div>
    <termination
      v-if="!terminationIsLoading"
      :animateButton="terminationAnimateButton"
      :editButton="terminationEditButton"
      @editTriggered="editTriggered"
      @submitTermination="submitTermination"
    />
  </div>
</template>

<script>
// import experiencesModal from "@/components/modal/profile/MA/employmentHr/experiencesModal.vue";
import experiencesModalSingle from "@/components/modal/profile/MA/employmentHr/experiencesModalSingle.vue";
import referencesModal from "@/components/modal/profile/MA/employmentHr/referencesModal.vue";
import educationInformationModal from "@/components/modal/profile/MA/employmentHr/educationInformationModal.vue";
import emergencyContactModal from "@/components/modal/profile/MA/employmentHr/emergencyContactModal.vue";
// import registrationsModal from "@/components/modal/profile/MA/employmentHr/registrationsModal.vue";
// import employmentPoliciesModal from "@/components/modal/profile/MA/employmentHr/employmentPoliciesModal.vue";
import contractSummaryModal from "@/components/modal/profile/MA/employmentHr/contractSummaryModal.vue";
import positionInformationModal from "@/components/modal/profile/MA/employmentHr/positionInformationModal.vue";
import miscellaneousInformationModal from "@/components/modal/profile/MA/employmentHr/miscellaneousInformationModal.vue";
import employmentChecksModal from "@/components/modal/profile/MA/employmentHr/employmentChecksModal.vue";
import contractSummary from "./employmentHR/contractSummary.vue";
import miscellaneousInformation from "./employmentHR/miscellaneousInformation.vue";
import employmentPolicies from "./employmentHR/employmentPolicies.vue";
import experienceInformation from "./employmentHR/experienceInformation.vue";
import reference from "./employmentHR/reference.vue";
import educationInformation from "./employmentHR/educationInformation.vue";
import registrations from "./employmentHR/registrations.vue";
import emergencyContact from "./employmentHR/emergencyContact.vue";
import positionInformation from "./employmentHR/positionInformation.vue";
// import employmentChecks from "./employmentHR/employmentChecks.vue";
import inductionChecklist from "./employmentHR/inductionChecklist.vue";
import termination from "./employmentHR/termination.vue";
export default {
  components: {
    contractSummary,
    miscellaneousInformation,
    employmentPolicies,
    experienceInformation,
    reference,
    educationInformation,
    registrations,
    emergencyContact,
    positionInformation,
    // employmentChecks,
    inductionChecklist,
    termination,
    contractSummaryModal,
    positionInformationModal,
    miscellaneousInformationModal,
    employmentChecksModal,
    // employmentPoliciesModal,
    // registrationsModal,
    emergencyContactModal,
    educationInformationModal,
    referencesModal,
    // experiencesModal,
    experiencesModalSingle,
  },
  emits: ["modalTrigger"],

  data() {
    return {
      workPattermIsLoading: false,
      miscIsLoading: false,
      employmentChecksIsLoading: false,
      employmentPoliciesIsLoading: false,
      experiencesInformationIsLoading: false,
      experiences: null,
      references: null,
      education: null,
      referencesIsLoading: false,
      educationInformationIsLoading: false,
      registrationsIsLoading: false,
      emergencyContactIsLoading: false,
      terminationIsLoading: false,
      terminationAnimateButton: false,
      terminationEditButton: false,
      singleEmergencyContact: null,
      // Contract Summary
      showContractSummary: true,
      contractSummaryAnimateButton: false,
      // Position Information
      showPositionInformation: true,
      positionInformationAnimateButton: false,
      // Miscellaneous Information
      showMiscellaneousInformation: true,
      miscellaneousInformationAnimateButton: false,
      // Employment Check
      showEmploymentChecks: true,
      employmentChecksAnimateButton: false,
      // Employment Policies
      showEmploymentPolicies: true,
      employmentPoliciesAnimateButton: false,
      // Registrations
      showRegistrations: true,
      registrationsAnimateButton: false,
      // Emergency Contact
      showEmergencyContact: true,
      emergencyContactAnimateButton: false,
      // Education Information
      showEducationInformation: true,
      educationInformationAnimateButton: false,
      // References
      showReferences: true,
      referencesAnimateButton: false,
      // References
      showExperiences: true,
      experiencesAnimateButton: false,
    };
  },
  created() {
    this.fetchWorkPatterns();
    this.fetchMiscInfo();
    this.fetchEmploymentChecks();
    this.fetchEmploymentPolicies();
    this.fetchExperiencesInformation();
    this.fetchReferencesInformation();
    this.fetchEducationInformation();
    this.fetchRegistrations();
    this.fetchEmergencyContacts();
    this.fetchTermination();
  },
  methods: {
    async fetchWorkPatterns() {
      try {
        this.workPattermIsLoading = true;
        await this.$store.dispatch("profile/fetchWorkPatterns");
        this.workPattermIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchMiscInfo() {
      try {
        this.miscIsLoading = true;
        await this.$store.dispatch("profile/fetchMiscInfo", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.miscIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchEmploymentChecks() {
      try {
        this.employmentChecksIsLoading = true;
        await this.$store.dispatch("profile/fetchEmploymentChecks", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.employmentChecksIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchEmploymentPolicies() {
      try {
        this.employmentPoliciesIsLoading = true;
        await this.$store.dispatch("profile/fetchEmploymentPolicies", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.employmentPoliciesIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchExperiencesInformation() {
      try {
        this.experiencesInformationIsLoading = true;
        await this.$store.dispatch("profile/fetchExperiencesInformation", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.experiencesInformationIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchReferencesInformation() {
      try {
        this.referencesIsLoading = true;
        await this.$store.dispatch("profile/fetchReferences", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.referencesIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchEducationInformation() {
      try {
        this.educationInformationIsLoading = true;
        await this.$store.dispatch("profile/fetchEducationInformation", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.educationInformationIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchRegistrations() {
      try {
        this.registrationsIsLoading = true;
        await this.$store.dispatch("profile/fetchRegistrations", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.registrationsIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchEmergencyContacts() {
      try {
        this.emergencyContactIsLoading = true;
        await this.$store.dispatch("profile/fetchEmergencyContacts", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.emergencyContactIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchTermination() {
      try {
        this.terminationIsLoading = true;
        await this.$store.dispatch("profile/fetchTermination", {
          user: this.$store.getters["profile/getProfile"].id,
        });
        this.terminationIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    editTriggered() {
      this.terminationEditButton = !this.terminationEditButton;
    },
    toggleContractModal() {
      this.showContractSummary = !this.showContractSummary;
    },
    toggleSummaryModal() {
      this.showPositionInformation = !this.showPositionInformation;
    },
    toggleMiscellaneousModal() {
      this.showMiscellaneousInformation = !this.showMiscellaneousInformation;
    },
    toggleEmploymentChecksModal() {
      this.showEmploymentChecks = !this.showEmploymentChecks;
    },
    toggleEmploymentPoliciesModal() {
      this.showEmploymentPolicies = !this.showEmploymentPolicies;
    },
    toggleRegistrationsModal() {
      this.showRegistrations = !this.showRegistrations;
    },
    toggleEmergencyContactModal(data) {
      this.showEmergencyContact = !this.showEmergencyContact;
      this.singleEmergencyContact = data;
    },
    toggleEducationInformationModal(data) {
      this.showEducationInformation = !this.showEducationInformation;
      this.education = data;
    },
    toggleReferencesModal(data) {
      this.showReferences = !this.showReferences;
      this.references = data;
    },
    toggleExperiencesModal(data) {
      this.showExperiences = !this.showExperiences;
      this.experiences = data;
    },
    // Contract Summary Update
    async submitContractSummaryModal(actionName, actionPayload) {
      // CONSOLE.LOG FORMDATA ITEMS
      // for (var pair of actionPayload.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      this.contractSummaryAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showContractSummary = !this.showContractSummary;
        this.contractSummaryAnimateButton = false;
      } catch (error) {
        this.contractSummaryAnimateButton = false;
      }
    },
    // Position Information Update
    async submitPositionInformation(actionName, actionPayload) {
      this.positionInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showPositionInformation = !this.showPositionInformation;
        this.positionInformationAnimateButton = false;
      } catch (error) {
        this.positionInformationAnimateButton = false;
      }
    },
    // Misc Information Update
    async submitMiscInformation(actionName, actionPayload) {
      this.miscellaneousInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showMiscellaneousInformation = !this.showMiscellaneousInformation;
        this.miscellaneousInformationAnimateButton = false;
      } catch (error) {
        this.miscellaneousInformationAnimateButton = false;
      }
    },
    // Registrations Update
    async submitRegistrations(actionName, actionPayload) {
      for (var pair of actionPayload.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      this.registrationsAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showRegistrations = !this.showRegistrations;
        this.registrationsAnimateButton = false;
      } catch (error) {
        this.registrationsAnimateButton = false;
      }
    },
    // Employment Checks Update
    async submitEmploymentChecks(actionName, actionPayload) {
      this.employmentChecksAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEmploymentChecks = !this.showEmploymentChecks;
        this.employmentChecksAnimateButton = false;
        this.fetchEmploymentChecks();
      } catch (error) {
        this.employmentChecksAnimateButton = false;
      }
    },
    // Experiences Create
    async submitExperiencesCreate(actionName, actionPayload) {
      this.experiencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showExperiences = !this.showExperiences;
        this.experiencesAnimateButton = false;
        this.fetchExperiencesInformation();
      } catch (error) {
        this.experiencesAnimateButton = false;
      }
    },
    // Experiences Update
    async submitExperiences(actionName, actionPayload) {
      this.experiencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showExperiences = !this.showExperiences;
        this.experiencesAnimateButton = false;
        this.fetchExperiencesInformation();
      } catch (error) {
        this.experiencesAnimateButton = false;
      }
    },
    // References Create
    async submitReferencesCreate(actionName, actionPayload) {
      this.referencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showReferences = !this.showReferences;
        this.referencesAnimateButton = false;
        this.fetchReferencesInformation();
      } catch (error) {
        this.referencesAnimateButton = false;
      }
    },
    // References Update
    async submitReferences(actionName, actionPayload) {
      this.referencesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showReferences = !this.showReferences;
        this.referencesAnimateButton = false;
        this.fetchReferencesInformation();
      } catch (error) {
        this.referencesAnimateButton = false;
      }
    },
    // Education Create
    async submitEducationCreate(actionName, actionPayload) {
      this.educationInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEducationInformation = !this.showEducationInformation;
        this.educationInformationAnimateButton = false;
        this.fetchEducationInformation();
      } catch (error) {
        this.educationInformationAnimateButton = false;
      }
    },
    // Education Update
    async submitEducation(actionName, actionPayload) {
      this.educationInformationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEducationInformation = !this.showEducationInformation;
        this.educationInformationAnimateButton = false;
        this.fetchEducationInformation();
      } catch (error) {
        this.educationInformationAnimateButton = false;
      }
    },
    // Emergency Contact Create
    async submitCreateEmergencyContact(actionName, actionPayload) {
      this.emergencyContactAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEmergencyContact = !this.showEmergencyContact;
        this.emergencyContactAnimateButton = false;
        this.fetchEmergencyContacts();
      } catch (error) {
        this.emergencyContactAnimateButton = false;
      }
    },
    // Emergency Contact Update
    async submitEmergency(actionName, actionPayload) {
      this.emergencyContactAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.showEmergencyContact = !this.showEmergencyContact;
        this.emergencyContactAnimateButton = false;
        this.fetchEmergencyContacts();
      } catch (error) {
        this.emergencyContactAnimateButton = false;
      }
    },
    // Termination Update
    async submitTermination(actionName, actionPayload) {
      this.terminationAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.terminationAnimateButton = false;
        this.editTriggered();
        this.fetchEmergencyContacts();
      } catch (error) {
        this.terminationAnimateButton = false;
      }
    },
    // Employment Policies Update
    async signEmploymentPolicy(actionName, actionPayload) {
      // this.employmentPoliciesAnimateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        // this.showEmploymentPolicies = !this.showEmploymentPolicies;
        // this.employmentPoliciesAnimateButton = false;
        this.fetchEmploymentPolicies();
      } catch (error) {
        // this.employmentPoliciesAnimateButton = false;
      }
    },
    // Trigger Action
    async submitAction(actionName, actionPayload) {
      try {
        await this.$store.dispatch(actionName, actionPayload);
        this.$toast.success(`Updated Successful`);
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteExperience(data) {
      try {
        await this.$store.dispatch("profile/deleteExperience", data);
        this.$toast.success(`Delete Successful`);
        this.showExperiences = true;
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteReference(data) {
      try {
        await this.$store.dispatch("profile/deleteReference", data);
        this.$toast.success(`Delete Successful`);
        this.showReferences = true;
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteEducation(data) {
      try {
        await this.$store.dispatch("profile/deleteEducation", data);
        this.$toast.success(`Delete Successful`);
        this.showEducationInformation = true;
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
    async deleteEmergencyContact(data) {
      try {
        await this.$store.dispatch("profile/deleteEmergencyContact", data);
        this.$toast.success(`Delete Successful`);
        this.showEmergencyContact = true;
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },

    submitModal() {
      console.log("Get rid of errors");
    },
  },
  computed: {
    getMiscData() {
      return this.$store.getters["profile/getMiscData"];
    },
    getEmploymentChecks() {
      return this.$store.getters["profile/getEmploymentChecks"];
    },
    getEmploymentPolicies() {
      return this.$store.getters["profile/getEmploymentPolicies"];
    },
    getExperiencesInformation() {
      return this.$store.getters["profile/getExperiencesInformation"];
    },
    getReferencesData() {
      return this.$store.getters["profile/getReferencesData"];
    },
    getEducationInformation() {
      return this.$store.getters["profile/getEducationInformation"];
    },
    getRegistrations() {
      return this.$store.getters["profile/getRegistrations"];
    },
    getEmergencyContacts() {
      return this.$store.getters["profile/getEmergencyContacts"];
    },
  },
};
</script>
