<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Position Summary
          </h2>
          <!-- Job Title / Contract Type -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full sm:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="job_title"
                >Job Title</label
              >
              <input
                type="text"
                name="job_title"
                v-model="job_title"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full sm:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="contract_type"
                >Contract Type</label
              >
              <select
                name="contract_type"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
                v-model="contract_type"
              >
                <option value="">Choose an option</option>
                <option value="full-time">Full-Time</option>
                <option value="part-time">Part-Time</option>
                <option value="contractor">Contractor</option>
              </select>
            </div>
          </div>
          <!-- Department / Reports To -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full sm:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="department"
                >Department</label
              >
              <select
                name="department"
                id="position-select"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
                v-model="department"
              >
                <option value="">Choose an option</option>
                <option value="admin">Admin</option>
                <option value="medical">Part-Time Pattern</option>
              </select>
            </div>
            <div class="w-full sm:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="reports_to"
                >Reports To</label
              >
              <select
                name="reports_to"
                id="position-select"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
                v-model="reports_to"
              >
                <option value="">Choose an option</option>
                <option value="jonathan">Jonathan</option>
                <option value="sam">Sam</option>
              </select>
            </div>
          </div>
          <!-- Probation end date / Notice Period -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full sm:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="probation_end_date"
                >Probation end date</label
              >
              <input
                placeholder="DD/MM/YYYY"
                onfocus="(this.type = 'date')"
                type="text"
                name="probation_end_date"
                v-model="probation_end_date"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full sm:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1 w-11/12"
                for="noticePeriod"
                >Notice Period</label
              >
              <div class="flex justify-between w-full sm:w-11/12">
                <input
                  type="number"
                  name="noticePeriod"
                  v-model="noticePeriodNumber"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-45P
                    h-10
                    pl-2
                  "
                />
                <select
                  name="noticePeriod"
                  id="leave-select"
                  v-model="noticePeriodUnit"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-45P
                    h-10
                  "
                >
                  <option value="">Choose an option</option>
                  <option value="days">Days</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  created() {
    this.job_title = this.getPositionInformation.job_title;
    this.contract_type = this.getPositionInformation.contract_type;
    this.department = this.getPositionInformation.department;
    this.reports_to = this.getPositionInformation.reports_to;
    this.probation_end_date = this.getPositionInformation.probation_end_date;
    this.noticePeriodNumber = this.getPositionInformation.notice_period;
    this.noticePeriodUnit = this.getPositionInformation.notice_period;
    // this.noticePeriodNumber = this.getPositionInformation.notice_period.split(
    //   " "
    // )[0];
    // this.noticePeriodUnit = this.getPositionInformation.notice_period.split(
    //   " "
    // )[1];
  },
  data() {
    return {
      job_title: "",
      contract_type: "",
      department: "",
      reports_to: "",
      probation_end_date: "",
      noticePeriodNumber: "",
      noticePeriodUnit: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = {
        position_summary: this.getPositionInformation.id,
        job_title: this.job_title,
        contract_type: this.contract_type,
        department: this.department,
        reports_to: this.reports_to,
        notice_period: this.noticePeriodNumber + this.noticePeriodUnit,
      };
      this.$emit("submit-modal", "profile/updatePositionInformation", payload);
    },
  },
  computed: {
    getPositionInformation() {
      return this.$store.getters["profile/getPositionInformation"];
    },
  },
};
</script>
