<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          max-h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Employment Checks
          </h2>
          <!-- Nationality Divider -->
          <div>
            <h3
              class="w-4/5 my-3 mx-auto overflow-hidden text-center text-orange"
            >
              Nationality
            </h3>
          </div>
          <!-- Passport Number / Country of Issue -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Passport Number -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="passportNumber"
                >Passport Number*</label
              >
              <input
                required
                type="text"
                name="passportNumber"
                placeholder="BG 123465"
                v-model="passportNumber"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Country of Issue -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="passportCountryOfIssue"
                >Country of issue*</label
              >
              <select
                required
                name="passportCountryOfIssue"
                v-model="passportCountryOfIssue"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.value"
                >
                  {{ country.label }}
                </option>
              </select>
            </div>
          </div>
          <!-- Expiry Date / UK Citizen -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Expiry Date -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="passportExpiryDate"
                >Expiry Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="passportExpiryDate"
                v-model="passportExpiryDate"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- UK Citizen -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-1 pb-1"
                for="ukCitizen"
                >UK Citizen</label
              >
              <div class="flex w-full md:w-11/12">
                <div class="">
                  <label class="inline-flex items-center">
                    <input
                      required
                      type="radio"
                      v-model="ukCitizen"
                      name="ukCitizen"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="ukCitizen"
                      name="ukCitizen"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Right to Work Divider -->
          <div v-if="ukCitizen === 0">
            <h3
              class="w-4/5 my-3 mx-auto overflow-hidden text-center text-orange"
            >
              Right To Work
            </h3>
          </div>
          <!-- Visa Required -->
          <div class="md:flex my-1 md:ml-10" v-if="ukCitizen === 0">
            <!-- Visa Required -->
            <!-- UK Citizen -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-1 pb-1"
                for="visaRequired"
                >Visa Required</label
              >
              <div class="flex w-full md:w-11/12">
                <div class="">
                  <label class="inline-flex items-center">
                    <input
                      required
                      type="radio"
                      v-model="visaRequired"
                      name="visaRequired"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="visaRequired"
                      name="visaRequired"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Visa Number / Visa Start Date -->
          <div
            v-if="ukCitizen === 0 && visaRequired === 1"
            class="md:flex my-1 md:ml-10"
          >
            <!-- Visa Number -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="visaNumber"
                >Visa Number*</label
              >
              <input
                required
                type="text"
                name="visaNumber"
                placeholder="123456798"
                v-model="visaNumber"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Visa Issued -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="visaStartDate"
                >Visa Issued*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="visaStartDate"
                v-model="visaStartDate"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Visa Expiry Date / Restriction Details -->
          <div
            v-if="ukCitizen === 0 && visaRequired === 1"
            class="md:flex my-1 md:ml-10"
          >
            <!-- Expiry Date -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="visaExpiryDate"
                >Expiry Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="visaExpiryDate"
                v-model="visaExpiryDate"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Restriction Details -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="visaRestrictionDetails"
                >Restriction Details*</label
              >
              <textarea
                required
                type="text"
                name="visaRestrictionDetails"
                placeholder=""
                v-model="visaRestrictionDetails"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                  pt-2
                "
              ></textarea>
            </div>
          </div>
          <!-- Right to Work / Share Code -->
          <div
            v-if="ukCitizen === 0 && visaRequired === 0"
            class="md:flex my-1 md:ml-10"
          >
            <!-- Right to work status -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="rightToWorkStatus"
                >Right to work status*</label
              >
              <select
                required
                name="rightToWorkStatus"
                v-model="rightToWorkStatus"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              >
                <option value="">Choose an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <!-- Share Code -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="shareCode"
                >Share Code*</label
              >
              <input
                required
                type="text"
                name="shareCode"
                placeholder="654987321"
                v-model="shareCode"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Date Issued / Date Checked -->
          <div
            v-if="ukCitizen === 0 && visaRequired === 0"
            class="md:flex my-1 md:ml-10"
          >
            <!-- Date Issued -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="dateIssued"
                >Date Issued*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="dateIssued"
                v-model="dateIssued"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Date Checked -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="dateChecked"
                >Date Checked*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="dateChecked"
                v-model="dateChecked"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Expiry Date / Right to Work Certificate -->
          <div
            v-if="ukCitizen === 0 && visaRequired === 0"
            class="md:flex my-1 md:ml-10"
          >
            <!-- Expiry Date -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="expiryDate"
                >Expiry Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="expiryDate"
                v-model="expiryDate"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Right to Work Certificate -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="rightToWorkCertificate"
                >Right to work Certificate*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="rightToWorkCertificateFile"
                  name="rightToWorkCertificate"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadRightToWorkCertificate"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      rightToWorkCertificateFileSizeOK
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        rightToWorkCertificate,
                        rightToWorkCertificateFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="rightToWorkCertificateFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- DBS Divider -->
          <div>
            <h3
              class="w-4/5 mt-3 mx-auto overflow-hidden text-center text-orange"
            >
              DBS
            </h3>
          </div>
          <!-- Self-declaration / DBS Conducted / Self-declaration certificate -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Self-declaration -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex mt-1 pb-1" for=""
                >Self-Declaration?</label
              >
              <div class="flex w-full md:w-11/12">
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="selfDeclaration"
                      name="selfDeclarationYes"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="mt-2 ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="selfDeclaration"
                      name="selfDeclarationNo"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
            <!-- DBS Conducted -->
            <div v-if="selfDeclaration === 0" class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex mt-1 pb-1" for=""
                >DBS Conducted</label
              >
              <div class="flex w-full md:w-11/12">
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="dbsConducted"
                      name="yes"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="mt-2 ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="dbsConducted"
                      name="no"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
            <!-- Self-Declaration Certificate -->
            <div v-if="selfDeclaration === 1" class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="selfDeclarationCertificate"
                >Self-Declaration Certificate</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="selfDeclarationCertificateFile"
                  name="selfDeclarationCertificate"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadSelfDeclarationCertificate"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      selfDeclarationCertificateFileSizeOK
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        selfDeclarationCertificate,
                        selfDeclarationCertificateFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="selfDeclarationCertificateFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- DBS Check Date / BDS Follow-Up -->
          <div v-if="selfDeclaration === 0" class="md:flex my-1 md:ml-10">
            <!-- DBS Check Date -->
            <div v-if="dbsConducted === 1" class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="dbsCheckDate"
                >DBS Check Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="dbsCheckDate"
                v-model="dbsCheckDate"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- BDS Follow-Up -->
            <div v-if="dbsConducted === 1" class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="dbsFollowUp"
                >DBS Follow-Up Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="dbsFollowUp"
                v-model="dbsFollowUp"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Certificate Number / DBS Certificate -->
          <div v-if="selfDeclaration === 0" class="md:flex my-1 md:ml-10">
            <!-- Certificate Number -->
            <div v-if="dbsConducted === 1" class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="certificateNumber"
                >Certificate Number*</label
              >
              <input
                required
                type="text"
                name="certificateNumber"
                placeholder="DBS 123456"
                v-model.trim="certificateNumber"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- DBS Certificate -->
            <div v-if="dbsConducted === 1" class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="dbsCertificate"
                >DBS Certificate*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="dbsCertificateFile"
                  name="dbsCertificate"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadDbsCertificate"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      dbsCertificateFileSizeOK
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(dbsCertificate, dbsCertificateFileSizeOK)
                    "
                  />
                </div>
              </div>
              <span v-if="dbsCertificateFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- Other Divider -->
          <div>
            <h3
              class="w-4/5 my-3 mx-auto overflow-hidden text-center text-orange"
            >
              Other
            </h3>
          </div>
          <!-- Driving Licence Number / Country of issue -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Driving Licence -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="drivingLicenceNumber"
                >Driving Licence Number</label
              >
              <input
                type="text"
                name="drivingLicenceNumber"
                placeholder="DRIV-120345"
                v-model.trim="drivingLicence"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <!-- Country of Issue -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="driversCountryOfIssue"
                >Country of issue*</label
              >
              <select
                name="driversCountryOfIssue"
                v-model="driversCountryOfIssue"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              >
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.value"
                >
                  {{ country.label }}
                </option>
              </select>
            </div>
          </div>
          <!-- Licence Class / Date of expiry -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Licence Class -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="licenceClass"
                >Licence Class</label
              >
              <select
                name="licenceClass"
                v-model="licenceClass"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              >
                <option value="">Choose an option</option>
                <option value="a">A</option>
                <option value="am">AM</option>
                <option value="b">b</option>
                <option value="bAuto">B auto</option>
                <option value="be">B+E</option>
                <option value="b1">B1</option>
                <option value="c">C</option>
                <option value="ce">C+E</option>
                <option value="c1">C1</option>
                <option value="c1E">C1+E</option>
                <option value="d">D</option>
                <option value="de">D+E</option>
                <option value="d1">D1</option>
                <option value="d1e">D1+E</option>
                <option value="f">F</option>
                <option value="g">G</option>
                <option value="h">H</option>
                <option value="k">K</option>
                <option value="q">Q</option>
              </select>
            </div>
            <!-- Date of Expiry -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="licenceExpiry"
                >Expiry Date*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="licenceExpiry"
                v-model="licenceExpiry"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getEmploymentChecks: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  mounted() {
    if (this.getEmploymentChecks && this.getEmploymentChecks !== null) {
      (this.passportNumber = this.getEmploymentChecks.passport_number),
        (this.passportCountryOfIssue =
          this.getEmploymentChecks.passport_country_of_issue),
        (this.passportExpiryDate =
          this.getEmploymentChecks.passport_date_of_expiry),
        (this.ukCitizen = this.getEmploymentChecks.is_uk_citizen),
        (this.rightToWorkStatus =
          this.getEmploymentChecks.right_to_work_status),
        (this.shareCode = this.getEmploymentChecks.share_code),
        (this.dateIssued = this.getEmploymentChecks.date_issued),
        (this.dateChecked = this.getEmploymentChecks.date_checked),
        (this.expiryDate = this.getEmploymentChecks.expiry_date),
        (this.rightToWorkCertificate =
          this.getEmploymentChecks.right_to_work_certificate),
        (this.visaRequired = this.getEmploymentChecks.visa_required),
        (this.visaNumber = this.getEmploymentChecks.visa_number),
        (this.visaStartDate = this.getEmploymentChecks.visa_start_date),
        (this.visaExpiryDate = this.getEmploymentChecks.visa_expiry_date),
        (this.visaRestrictionDetails = this.getEmploymentChecks.restrictions),
        (this.selfDeclaration =
          this.getEmploymentChecks.self_declaration_completed),
        (this.selfDeclarationCertificate =
          this.getEmploymentChecks.self_declaration_certificate),
        (this.dbsConducted = this.getEmploymentChecks.is_dbs_conducted),
        (this.dbsCheckDate = this.getEmploymentChecks.dbs_conducted_date),
        (this.dbsFollowUp = this.getEmploymentChecks.follow_up_date),
        (this.certificateNumber =
          this.getEmploymentChecks.dbs_certificate_number),
        (this.dbsCertificate = this.getEmploymentChecks.dbs_certificate),
        (this.drivingLicence = this.getEmploymentChecks.driving_license_number),
        (this.driversCountryOfIssue =
          this.getEmploymentChecks.driving_license_country_of_issue),
        (this.licenceClass = this.getEmploymentChecks.driving_license_class),
        (this.licenceExpiry =
          this.getEmploymentChecks.driving_license_date_of_expiry);
    }
  },
  data() {
    return {
      passportNumber: "",
      passportCountryOfIssue: "",
      passportExpiryDate: "",
      ukCitizen: 1,
      rightToWorkStatus: "",
      shareCode: "",
      dateIssued: "",
      dateChecked: "",
      expiryDate: "",
      rightToWorkCertificate: null,
      rightToWorkCertificateFileSizeOK: false,
      visaRequired: 0,
      visaNumber: "",
      visaStartDate: "",
      visaExpiryDate: "",
      visaRestrictionDetails: "",
      selfDeclaration: 0,
      selfDeclarationCertificate: null,
      selfDeclarationCertificateFileSizeOK: false,
      dbsConducted: 1,
      dbsCheckDate: "",
      dbsFollowUp: "",
      certificateNumber: "",
      dbsCertificate: null,
      dbsCertificateFileSizeOK: false,
      drivingLicence: 1,
      driversCountryOfIssue: "",
      licenceClass: "",
      licenceExpiry: "",
    };
  },
  methods: {
    uploadRightToWorkCertificate() {
      this.rightToWorkCertificate =
        this.$refs.rightToWorkCertificateFile.files[0];
      if (this.rightToWorkCertificate.size > 1024 * 1024) {
        this.rightToWorkCertificate = null;
        return (this.rightToWorkCertificateFileSizeOK = true);
      } else {
        return (this.rightToWorkCertificateFileSizeOK = false);
      }
    },
    uploadSelfDeclarationCertificate() {
      this.selfDeclarationCertificate =
        this.$refs.selfDeclarationCertificateFile.files[0];
      if (this.selfDeclarationCertificate.size > 1024 * 1024) {
        this.selfDeclarationCertificate = null;
        return (this.selfDeclarationCertificateFileSizeOK = true);
      } else {
        return (this.selfDeclarationCertificateFileSizeOK = false);
      }
    },
    uploadDbsCertificate() {
      this.dbsCertificate = this.$refs.dbsCertificateFile.files[0];
      if (this.dbsCertificate.size > 1024 * 1024) {
        this.dbsCertificate = null;
        return (this.dbsCertificateFileSizeOK = true);
      } else {
        return (this.dbsCertificateFileSizeOK = false);
      }
    },
    checkFile(attachment, sizeCheck) {
      if (typeof attachment === "object" && attachment !== null) {
        if (sizeCheck) {
          return "Click here to attach a file (< 1MB)...";
        } else {
          return attachment.name;
        }
      } else if (attachment !== null) {
        const url = new URL(attachment);
        const newerURL = url.pathname.split("/");
        let lastElement = newerURL[newerURL.length - 1];
        let arr = lastElement.split(".");
        arr.shift();
        lastElement = arr.join(".");
        return lastElement;
      }
      return;
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const formData = new FormData();
      formData.append("employment_check", this.getEmploymentChecks.id);
      formData.append("passport_number", this.passportNumber);
      formData.append("passport_country_of_issue", this.passportCountryOfIssue);
      formData.append("passport_date_of_expiry", this.passportExpiryDate);
      formData.append("is_uk_citizen", this.ukCitizen);
      formData.append("right_to_work_status", this.rightToWorkStatus);
      formData.append("share_code", this.shareCode);
      formData.append("date_issued", this.dateIssued);
      formData.append("date_checked", this.dateChecked);
      formData.append("expiry_date", this.expiryDate);
      formData.append("visa_required", this.visaRequired);
      formData.append("visa_number", this.visaNumber);
      formData.append("visa_start_date", this.visaStartDate);
      formData.append("visa_expiry_date", this.visaExpiryDate);
      formData.append("restrictions", this.visaRestrictionDetails);
      formData.append("is_dbs_conducted", this.dbsConducted);
      formData.append("dbs_conducted_date", this.dbsCheckDate);
      formData.append("follow_up_date", this.dbsFollowUp);
      formData.append("dbs_certificate_number", this.certificateNumber);
      formData.append("driving_license_number", this.drivingLicence);
      formData.append(
        "driving_license_country_of_issue",
        this.driversCountryOfIssue
      );
      formData.append("driving_license_class", this.licenceClass);
      formData.append("driving_license_date_of_expiry", this.licenceExpiry);
      if (typeof this.rightToWorkCertificate === "object") {
        formData.append(
          "right_to_work_certificate",
          this.rightToWorkCertificate
        );
      }
      if (typeof this.dbsCertificate === "object") {
        formData.append("dbs_certificate", this.dbsCertificate);
      }
      if (typeof this.selfDeclaration === "object") {
        formData.append("self_declaration_certificate", this.selfDeclaration);
      }
      this.$emit("submit-modal", "profile/updateEmploymentChecks", formData);
    },
  },
  computed: {
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
