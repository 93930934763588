<template>
  <div class="border rounded-lg">
    <h2 class="text-orange pl-2">Annual Leave</h2>
    <div class="flex text-sm">
      <div class="flex-col w-1/2">
        <div class="border-2 border-lightGrey m-2 p-2 rounded-md">
          <p class="font-semibold">
            Upcoming time off:
          </p>
          <p>
            Mon, 11th Dec 2021
          </p>
          <br />
          <p><span class="font-semibold">Approved by</span> Manager</p>
          <p>on Tue, 12th Jan 2021</p>
        </div>
        <div class="border-2 border-lightGrey m-2 p-2 rounded-md">
          <p class="font-semibold">
            Upcoming time off:
          </p>
          <p>
            Mon, 11th Dec 2021
          </p>
          <br />
          <p><span class="font-semibold">Approved by</span> Manager</p>
          <p>on Tue, 12th Jan 2021</p>
        </div>
      </div>
      <div class="flex justify-center items-center w-1/2">
        <radial-progress-bar
          :diameter="210"
          :completed-steps="usedDays"
          :total-steps="totalDays"
          :startColor="'#00A499'"
          :stopColor="'#00A499'"
          innerStrokeColor="#E8EDEE"
        >
          <p>{{ usedDays }} / {{ totalDays }} Days</p>
        </radial-progress-bar>
      </div>
    </div>
    <div class="flex justify-center">
      <p class="border bg-orange rounded-3xl px-2 py-1 mb-2 cursor-pointer">
        REQUEST TIME OFF
      </p>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from "@/components/ui/baseComponents/RadialProgressBar.vue";

export default {
  components: {
    RadialProgressBar,
  },
  data() {
    return {
      usedDays: 7,
      totalDays: 20,
    };
  },
};
</script>
