<template>
  <!-- Position Information -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Position Information</h2>
        <div class="flex items-start cursor-pointer">
          <span class="material-icons" @click="contractSummaryModal()">
            edit
          </span>
        </div>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Job Title:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.job_title">
          {{ getPositionInformation.job_title.toUpperCase() }}
        </p>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Contract type:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.contract_type">
          {{ getPositionInformation.contract_type.toUpperCase() }}
        </p>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Department:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.department">
          {{ getPositionInformation.department.toUpperCase() }}
        </p>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Reports to:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.reports_to">
          {{ getPositionInformation.reports_to.toUpperCase() }}
        </p>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Probation end date:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.probation_end_date">
          {{ getPositionInformation.probation_end_date.toUpperCase() }}
        </p>
      </div>
      <div class="flex m-2 ml-3">
        <p class="flex-1">Notice period:</p>
        <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
        <p class="w-2/5" v-if="getPositionInformation.notice_period">
          {{ getPositionInformation.notice_period.toUpperCase() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["modalTrigger"],
  data() {
    return {
      showPositionInformationModal: false,
    };
  },
  methods: {
    contractSummaryModal() {
      this.showPositionInformationModal = !this.showPositionInformationModal;
      this.$emit("modalTrigger", this.showPositionInformationModal);
    },
  },
  computed: {
    getPositionInformation() {
      return this.$store.getters["profile/getPositionInformation"];
    },
  },
};
</script>
