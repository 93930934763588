<template>
  <!-- Employment Policies -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Employment Policies</h2>
        <!-- <div class="flex items-start cursor-pointer">
          <span class="material-icons" @click="employmentPoliciesModal()">
            edit
          </span>
        </div> -->
      </div>
      <table class="w-full table-fixed text-center">
        <thead>
          <tr>
            <th class="w-1/3 lg:w-1/4 py-2">Name</th>
            <th class="w-1/3 lg:w-1/4">Sign Date</th>
            <th class="md:hidden lg:table-cell lg:w-1/4">Upload Date</th>
            <th class="w-1/6">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="policy in getEmploymentPolicies" :key="policy.id">
            <td class="underline cursor-pointer text-left pl-2">
              <a
                class="w-2/5 underline cursor-pointer"
                :href="policy.attachment"
                target="_blank"
                ><span class="md:hidden lg:inline-block material-icons text-sm">
                  description
                </span>
                {{ policy.name.toUpperCase() }}
              </a>
            </td>
            <td>{{ convertedTime(policy.sign_date) }}</td>
            <td class="md:hidden lg:table-cell lg:w-1/4">
              {{ convertedTime(policy.created_at) }}
            </td>
            <td>
              <span
                @click="showActionsMenu(policy)"
                class="material-icons cursor-pointer"
              >
                more_horiz
              </span>
              <div
                v-bind:class="{
                  hidden: showActionsModal !== policy.id,
                }"
                class="absolute w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <p
                  class="block cursor-pointer px-4 py-2 text-md text-darkGrey hover:bg-lightGrey hover:text-grey"
                >
                  <span class="flex flex-col" @click="signPolicy(policy.id)">
                    <span> Sign Policy </span>
                  </span>
                </p>
                <p
                  class="block cursor-pointer px-4 py-2 text-md text-darkGrey hover:bg-lightGrey hover:text-grey"
                >
                  <span class="flex flex-col">
                    <a :href="policy.attachment" download="Policy">
                      <span> Download </span>
                    </a>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  emits: ["modalTrigger", "policySign"],
  props: {
    getEmploymentPolicies: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEmploymentPoliciesModal: false,
      showActionsModal: 0,
    };
  },
  methods: {
    showActionsMenu(el) {
      if (this.showActionsModal === el.id) {
        this.showActionsModal = 0;
      } else {
        this.showActionsModal = el.id;
      }
    },
    signPolicy(policy) {
      const payload = {
        employment_policy: policy,
        sign_date: moment().format("YYYY-MM-DD"),
      };
      this.$emit("policySign", "profile/signEmploymentPolicy", payload);
    },
    employmentPoliciesModal() {
      this.showEmploymentPoliciesModal = !this.showEmploymentPoliciesModal;
      this.$emit("modalTrigger", this.showEmploymentPoliciesModal);
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>
