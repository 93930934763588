<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Emergency Contact
          </h2>
          <div class="border border-grey rounded-lg p-3 my-1">
            <!-- Contact Divider -->
            <div v-if="getEmergencyContacts">
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                <span v-if="getEmergencyContacts.is_primary === 1"
                  >Primary Contact</span
                >
                <span v-else>Contact</span>
              </h3>
            </div>
            <div v-else>
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                <span>Add Contact</span>
              </h3>
            </div>

            <!-- Name / Relationship -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Name -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="contactName"
                  >Name</label
                >
                <input
                  required
                  placeholder=""
                  type="text"
                  name="contactName"
                  v-model="contactName"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Relationship -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="contactRelationship"
                  >Relationship*</label
                >
                <input
                  required
                  placeholder=""
                  type="text"
                  name="contactRelationship"
                  v-model="contactRelationship"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Phone One / Phone Two -->
            <div
              class="
                flex flex-col
                items-center
                sm:flex-row
                my-1
                sm:ml-2
                md:ml-10
              "
            >
              <!-- Phone One -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="contactPhoneNumberOne"
                  >Phone*</label
                >
                <input
                  required
                  placeholder=""
                  type="number"
                  name="contactPhoneNumberOne"
                  v-model="contactPhoneNumberOne"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
              <!-- Phone Two -->
              <div class="w-full sm:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="contactPhoneNumberTwo"
                  >Secodary Phone Number*</label
                >
                <input
                  required
                  placeholder=""
                  type="number"
                  name="contactPhoneNumberTwo"
                  v-model="contactPhoneNumberTwo"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    sm:w-11/12
                    h-10
                    pl-2
                  "
                />
              </div>
            </div>
            <!-- Is Primary -->
            <div class="flex items-center my-1 sm:ml-2 md:ml-10">
              <label
                class="font-semibold w-full text-grey flex mt-1 pb-1"
                for="isPrimary"
                >Primary Contact?</label
              >
              <div class="flex w-full">
                <div class="">
                  <label class="inline-flex items-center">
                    <input
                      required
                      type="radio"
                      v-model="isPrimary"
                      name="isPrimaryYes"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="isPrimary"
                      name="isPrimaryNo"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="getEmergencyContacts"
            class="flex justify-end bg-white pt-2"
          >
            <p
              class="
                w-20
                py-1
                mx-1
                my-1
                md:mr-5
                text-center text-white
                bg-red
                rounded-full
                cursor-pointer
              "
              @click.prevent="deleteOption()"
            >
              Delete
            </p>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getEmergencyContacts: {
      type: Object,
    },
  },
  emits: ["close-modal", "submit-modal"],
  created() {
    // Takes original Data Array Sorts it so the PRIMARY is the first element and saves a copy in the variable
    // this.filteredArray = [...this.getEmergencyContacts].sort((a, b) => {
    //   return b.is_primary - a.is_primary;
    // });
    if (this.getEmergencyContacts) {
      this.contactID = this.getEmergencyContacts.id;
      this.contactName = this.getEmergencyContacts.name;
      this.contactRelationship = this.getEmergencyContacts.relationship;
      this.contactPhoneNumberOne = this.getEmergencyContacts.primary_phone;
      this.contactPhoneNumberTwo = this.getEmergencyContacts.secondary_phone;
      this.isPrimary = this.getEmergencyContacts.is_primary;
    }
  },
  data() {
    return {
      filteredArray: null,
      contactID: "",
      contactName: "",
      contactRelationship: "",
      contactPhoneNumberOne: "",
      contactPhoneNumberTwo: "",
      isPrimary: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = {
        name: this.contactName,
        relationship: this.contactRelationship,
        primary_phone: this.contactPhoneNumberOne.toString(),
        secondary_phone: this.contactPhoneNumberTwo.toString(),
        is_primary: this.isPrimary,
      };
      if (this.getEmergencyContacts) {
        payload.emergency_contact = this.contactID;
        this.$emit("submit-modal", "profile/updateEmergencyContact", payload);
      } else {
        payload.user = this.$store.getters["profile/getProfile"].id;
        this.$emit(
          "submit-modal-create",
          "profile/createEmergencyContact",
          payload
        );
      }
    },
    deleteOption() {
      const payload = {
        emergency_contact: this.contactID,
      };
      this.$emit("delete-item", payload);
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
