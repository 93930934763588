<template>
  <teleport to="body"> </teleport>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-x-1 m-auto w-11.5/12 pt-5"
  >
    <!-- Col 1 -->
    <div class="grid col-span-2">
      <annual-leave />
    </div>
    <!-- Col 2 -->
    <div class="grid">
      <sickness />
    </div>
    <!-- Col 3 -->
    <div class="grid">
      <lateness />
    </div>
    <!-- Col 4 -->
    <div class="grid">
      <otherAbsence />
    </div>
  </div>
</template>

<script>
import annualLeave from "./sicknessLeave/annualLeave.vue";
import sickness from "./sicknessLeave/sickness.vue";
import lateness from "./sicknessLeave/lateness.vue";
import otherAbsence from "./sicknessLeave/otherAbsence.vue";
export default {
  components: {
    annualLeave,
    sickness,
    lateness,
    otherAbsence,
  },
  emits: ["modalTrigger"],
  data() {
    return {
      // Contract Summary
      showContractSummary: true,
      contractSummaryAnimateButton: false,
      // Position Information
      showPositionInformation: true,
      positionInformationAnimateButton: false,
      // Miscellaneous Information
      showMiscellaneousInformation: true,
      miscellaneousInformationAnimateButton: false,
      // Employment Check
      showEmploymentChecks: true,
      employmentChecksAnimateButton: false,
      // Employment Policies
      showEmploymentPolicies: true,
      employmentPoliciesAnimateButton: false,
      // Registrations
      showRegistrations: true,
      registrationsAnimateButton: false,
      // Emergency Contact
      showEmergencyContact: true,
      emergencyContactAnimateButton: false,
      // Education Information
      showEducationInformation: true,
      educationInformationAnimateButton: false,
      // References
      showReferences: true,
      referencesAnimateButton: false,
      // References
      showExperiences: true,
      experiencesAnimateButton: false,
    };
  },
  methods: {
    toggleContractModal() {
      this.showContractSummary = !this.showContractSummary;
    },
    toggleSummaryModal() {
      this.showPositionInformation = !this.showPositionInformation;
    },
    toggleMiscellaneousModal() {
      this.showMiscellaneousInformation = !this.showMiscellaneousInformation;
    },
    toggleEmploymentChecksModal() {
      this.showEmploymentChecks = !this.showEmploymentChecks;
    },
    toggleEmploymentPoliciesModal() {
      this.showEmploymentPolicies = !this.showEmploymentPolicies;
    },
    toggleRegistrationsModal() {
      this.showRegistrations = !this.showRegistrations;
    },
    toggleEmergencyContactModal() {
      this.showEmergencyContact = !this.showEmergencyContact;
    },
    toggleEducationInformationModal() {
      this.showEducationInformation = !this.showEducationInformation;
    },
    toggleReferencesModal() {
      this.showReferences = !this.showReferences;
    },
    toggleExperiencesModal() {
      this.showExperiences = !this.showExperiences;
    },
    async submitModal(actionPayload) {
      for (var pair of actionPayload.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      this.contractSummaryAnimateButton = true;
      this.positionInformationAnimateButton = true;
      // try {
      //   await this.$store.dispatch("cleaner/newThread", actionPayload);
      //   this.$router.push({
      //     path: "/cs/communicationBook/" + this.getPost.id,
      //     query: { new: "true" },
      //   });
      // } catch (error) {
      //   this.animateButton = false;
      //   this.$toast.error("Oops something went wrong");
      // }
    },
  },
};
</script>
