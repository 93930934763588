<template>
  <!-- Termination -->
  <div class="col-span-full mb-10">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Termination</h2>
        <div class="flex items-start cursor-pointer">
          <span class="material-icons" @click="editMode()"> edit </span>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="w-full">
          <div class="ml-3">
            <label for="terminationDate" class="">Termination date:</label>
            <br />
            <input
              required
              :disabled="!editButton"
              name="terminationDate"
              class="w-full h-12 rounded-md border-2 border-grey"
              type="date"
              v-model="date"
            />
          </div>
          <div class="ml-3">
            <label for="reasons" class="">Reason:</label>
            <br />
            <select
              required
              :disabled="!editButton"
              name="reasons"
              class="w-full h-12 rounded-md border-2 border-grey"
              v-model="reason"
            >
              <option value="reason1">Reason 1</option>
              <option value="reason2">Reason 2</option>
              <option value="reason3">Reason 3</option>
              <option value="reason4">Reason 4</option>
            </select>
          </div>
          <div class="m-3 flex items-center">
            <label for="exitInterview" class="mr-5 w-full"
              >Exit interview performed?</label
            >
            <label class="inline-flex items-center">
              <input
                :disabled="!editButton"
                required
                type="radio"
                v-model="exitInterview"
                name="exitInterviewYes"
                :value="1"
              />
              <span class="mx-2">Yes</span>
            </label>
            <label class="inline-flex items-center">
              <input
                :disabled="!editButton"
                required
                type="radio"
                v-model="exitInterview"
                name="exitInterviewNo"
                :value="0"
              />
              <span class="ml-2">No</span>
            </label>
          </div>
        </div>
        <div class="w-full">
          <div class="mx-3 h-1/2">
            <label for="details" class="">Reason:</label>
            <br />
            <textarea
              :disabled="!editButton"
              name="details"
              id="details"
              class="border-2 border-grey rounded-md w-full h-full"
              v-model="detail"
            ></textarea>
          </div>
          <div class="flex mr-3 pt-5 justify-end" v-if="editButton">
            <div class="mx-2 pt-3">
              <p
                class="
                  px-12
                  py-2
                  text-white
                  font-semibold
                  bg-orange
                  rounded-full
                  cursor-pointer
                "
                @click="editMode"
              >
                Cancel
              </p>
            </div>
            <div class="mx-2 pt-3">
              <base-button @click="submitAction" :animateButton="animateButton">
                Save
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["submitTermination", "editTriggered"],

  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    editButton: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      terminationId: null,
      date: null,
      reason: null,
      detail: null,
      exitInterview: null,
      showReferencesModal: false,
    };
  },
  created() {
    if (this.getTermination && this.getTermination !== null) {
      (this.terminationId = this.getTermination.id),
        (this.date = this.getTermination.date),
        (this.reason = this.getTermination.reason),
        (this.detail = this.getTermination.detail),
        (this.exitInterview = this.getTermination.is_exit_interview_performed);
    }
  },
  methods: {
    submitAction() {
      const payload = {
        termination: this.terminationId,
        date: this.date,
        reason: this.reason,
        detail: this.detail,
        is_exit_interview_performed: this.is_exit_interview_performed,
      };
      this.$emit("submitTermination", "profile/updateTermination", payload);
    },
    editMode() {
      this.$emit("editTriggered");
    },
  },
  computed: {
    getTermination() {
      return this.$store.getters["profile/getTermination"];
    },
  },
};
</script>
